import * as THREE from 'three';
import "styles/components/character-app/_character-app.scss";
import CharacterTabs from "components/CharacterApp/interface/CharacterTabs/CharacterTabs";
import {CharacterParamsProvider} from "contexts/CharacterParams";
import {Canvas} from "@react-three/fiber";
import {Environment, OrbitControls} from "@react-three/drei";
import Character from "components/CharacterApp/characters/Character";
import {Base} from "components/CharacterApp/Base";
import MenuBar from "components/CharacterApp/interface/MenuBar/MenuBar";
import useBEM from "hooks/useBEM";
import {CharacterExportProvider} from "contexts/CharacterExport";
import PowersBottle from "components/CharacterApp/interface/PowersBottle";
import RenderPreview from "components/CharacterApp/interface/RenderPreview";
import {useEffect, useState} from "react";
import {isDesktop, isStandalone, PUBLIC_URL} from "helpers/app";
import IdleAnimation from "components/CharacterApp/animations/IdleAnimation";
import BackButton from "components/CharacterApp/interface/BackButton";
import useAppLoading from "hooks/useAppLoading";
import BottleEmpty from "icons/bottle/BottleEmpty";
import apiService from "helpers/apiService";

const CharacterAppLoader = () => {
  const classes = useBEM('character-app');
  const {progress} = useAppLoading();

  return <div className={classes('loader')} style={{
    '--app-progress': (progress * 100).toFixed(2) + '%',
  }}>
    <div className={classes('loader-progress', {
      complete: progress >= 0.99,
    })}>
      <BottleEmpty/>
    </div>
  </div>
}

const CharacterAppContent = () => {
  const classes = useBEM('character-app');

  return <>
    {!isStandalone && <BackButton/>}
    <CharacterParamsProvider>
      <div className={classes('layout')}>
        <CharacterExportProvider>
          <div className={classes('viewport')}>
            <Canvas shadows
                    frameloop={'demand'}
                    camera={
                      {
                        position: [0, 0.45, 2.5],
                        rotation: [Math.PI * 0.05, 0, 0],
                        up: [0, 1, 0],
                        fov: 55
                      }
                    }
                    dpr={window.devicePixelRatio * 2}
                    gl={{
                      antialias: true,
                      alpha: true,
                      pixelRatio: window.devicePixelRatio * 2,
                      toneMapping: THREE.NeutralToneMapping
                    }}
            >
              <IdleAnimation min={-0.03} max={0.02} time={2500} fps={30}>
                <Character isMain={true}/>
                <Base/>
              </IdleAnimation>
              <directionalLight
                position={[2, 3, 3]}
                intensity={1}
                castShadow
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-radius={6}
                shadow-blurSamples={25}
              />
              <Environment files={PUBLIC_URL + '/assets/hdri/environment.hdr'} environmentIntensity={0.5}
                           environmentRotation={[0, -Math.PI / 4, 0]}/>
              <OrbitControls target={[0, 0.6, 0]} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2}
                             enablePan={false} enableZoom={false}/>
            </Canvas>
            <PowersBottle/>
          </div>
          <RenderPreview/>

          <div className={classes('library')}>
            <CharacterTabs/>
          </div>
        </CharacterExportProvider>
        <MenuBar/>
      </div>
    </CharacterParamsProvider>
  </>
}

const CharacterApp = () => {
  const classes = useBEM('character-app');
  const [aspectRatio, setAspectRatio] = useState();
  const {isLoading} = useAppLoading();

  useEffect(() => {
    apiService.getToken();
    if (isStandalone) {
      document.body.classList.add('standalone');
      window.addEventListener('scroll', (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
      });
    }

    const onResize = () => {
      const aspectRatio = (isStandalone || window.innerWidth < 1024) && !isDesktop ? Math.max(window.innerWidth / window.innerHeight, window.innerHeight / window.innerWidth) : 1.59;
      setAspectRatio(aspectRatio);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return <div className={classes({
    standalone: isStandalone,
  })} style={{
    aspectRatio,
  }}>
    {isLoading ? <CharacterAppLoader/> : <CharacterAppContent/>}
  </div>
};

export default CharacterApp;
