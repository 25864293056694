/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/characters/captain-altrini.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import {useCharacterParams} from "contexts/CharacterParams";
import {PUBLIC_URL} from "helpers/app";

export function CaptainAltrini(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/characters/captain-altrini.glb');
  const { showEars } = useCharacterParams();
  const characterMaterials = useCharacterMaterials(materials);
  
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.771, 0.039]} rotation={[-0.271, 0, 0]}>
        <mesh geometry={nodes.Face.geometry} material={characterMaterials.Skin} position={[0, 0.536, 0.007]} rotation={[0.318, 0, 0]} scale={0.922}>
          {showEars && <mesh geometry={nodes.Ears.geometry} material={characterMaterials.Skin} position={[0, -0.127, 0.127]} />}
          <mesh geometry={nodes.Eyelids.geometry} material={characterMaterials.Eyelid} />
          <group position={[0, 0.042, 0.142]} rotation={[-0.232, 0.109, -0.007]}>
            <mesh geometry={nodes.Eyes_1.geometry} material={characterMaterials.Eye} />
            <mesh geometry={nodes.Eyes_2.geometry} material={characterMaterials.Eyeris} />
          </group>
        </mesh>
      </group>
      <group position={[0, 0.457, -0.265]}>
        <mesh geometry={nodes.Arms_1.geometry} material={characterMaterials.SuitSecondary} />
        <mesh geometry={nodes.Arms_2.geometry} material={characterMaterials.SuitPrimaryDark} />
        <mesh geometry={nodes.Arms_3.geometry} material={characterMaterials.SuitPrimary} />
        <mesh geometry={nodes.Arms_4.geometry} material={characterMaterials.SuitSecondary} />
      </group>
      <group position={[-0.003, 0.575, -0.07]} rotation={[1.742, 0, 0]} scale={2.476}>
        <mesh geometry={nodes.Badge_1.geometry} material={characterMaterials.SuitSecondary} />
        <mesh geometry={nodes.Badge_2.geometry} material={characterMaterials.SuitBadge} />
      </group>
      <mesh geometry={nodes.Belt.geometry} material={characterMaterials.SuitBlack} position={[0, 0.457, -0.265]}>
        <group position={[0, -0.028, 0.193]} rotation={[Math.PI / 2, 0, 0]} scale={0.034}>
          <mesh geometry={nodes.Bottles_1.geometry} material={characterMaterials.BottleVanilla} />
          <mesh geometry={nodes.Bottles_2.geometry} material={characterMaterials.BottleStrawberry} />
        </group>
        <group position={[0, -0.028, 0.193]} rotation={[Math.PI / 2, 0, 0]} scale={0.034}>
          <mesh geometry={nodes.Buckle_1.geometry} material={characterMaterials.SuitPrimary} />
          <mesh geometry={nodes.Buckle_2.geometry} material={characterMaterials.SuitSecondary} />
          <mesh geometry={nodes.Buckle_3.geometry} material={characterMaterials.SuitBlack} />
        </group>
      </mesh>
      <mesh geometry={nodes.Collar.geometry} material={characterMaterials.SuitPrimary} position={[0, 0.767, 0.051]} scale={1.525} />
      <group position={[0, 0.457, -0.265]}>
        <mesh geometry={nodes.Legs_1.geometry} material={characterMaterials.SuitPrimary} />
        <mesh geometry={nodes.Legs_2.geometry} material={characterMaterials.SuitSecondary} />
        <mesh geometry={nodes.Legs_3.geometry} material={characterMaterials.SuitPrimaryDark} />
        <mesh geometry={nodes.Legs_4.geometry} material={characterMaterials.SuitSecondary} />
      </group>
      <mesh geometry={nodes.Neck.geometry} material={characterMaterials.Skin} position={[0, 0.771, 0.039]} scale={1.525} />
      <group position={[0, 0.456, -0.265]}>
        <mesh geometry={nodes.Torso_1.geometry} material={characterMaterials.SuitPrimary} />
        <mesh geometry={nodes.Torso_2.geometry} material={characterMaterials.SuitPrimaryDark} />
        <mesh geometry={nodes.Torso_3.geometry} material={characterMaterials.SuitSecondary} />
      </group>
    </group>
  )
}

//useGLTF.preload(PUBLIC_URL + '/assets/models/characters/captain-altrini.glb')
