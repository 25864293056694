import {} from '@google/model-viewer';
import Button from "components/interface/Button";
import {useCallback, useEffect, useState} from "react";
import ARIcon from "icons/ARIcon";
import {exportHero} from "helpers/app";
import {toast} from "react-toastify";

const ARButton = ({scene}) => {
  const [viewer, setViewer] = useState();
  const [isLoading, setLoading] = useState(false);
  const [arAvailable, setArAvailable] = useState(true);

  const handleExport = useCallback(async () => {
    if (!scene || !viewer) return;
    setLoading(true);

    try {
      const gltf = await exportHero(scene, 'gltf');
      viewer.src = URL.createObjectURL(gltf);
    }
    catch (e) {}

    try {
      const usdz = await exportHero(scene, 'usdz');
      viewer.iosSrc = URL.createObjectURL(usdz);
    }
    catch (e) {}

    setLoading(false);
  }, [setLoading, scene, viewer]);

  const handleArAnavailable = useCallback(() => {
    setArAvailable(false);
    toast('AR is not available for your device. We recommend using a mobile device to use this feature.');
  }, [setArAvailable]);

  const handleAr = useCallback(() => {
    if (viewer) {
      viewer.activateAR().then(() => {
        if (!viewer.canActivateAR) {
          handleArAnavailable();
        }
      }).catch((e) => {
        console.error(e);
        handleArAnavailable();
      });
    }
  }, [viewer, handleArAnavailable]);

  useEffect(() => {
    if (scene && viewer && handleExport) {
      handleExport().then(() => {
        setArAvailable(true);
      }).catch(() => {
        toast('Could not export 3D files. Please try again.');
      });
    }
  }, [scene, viewer, handleExport]);

  return <>
    <Button disabled={isLoading || !arAvailable} onClick={handleAr} variant={'square'} fullWidth leftSection={<ARIcon/>}>
      {isLoading ? 'Preparing for 3D...' : 'View in 3D'}
      <model-viewer class="export-viewer" ref={setViewer} ar
                    ar-modes="quick-look webxr" reveal="manual"/>
    </Button>
  </>
}

export default ARButton;
