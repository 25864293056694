import "styles/components/_rotate-overlay.scss";
import useBEM from "hooks/useBEM";

export default function RotateOverlay() {
  const classes = useBEM('rotate-overlay');

  return <div className={classes()}>
    <div className={classes('inner')}>
      <h4 className={classes('title')}>Rotate to play</h4>
      <div className={classes('animation')}/>
    </div>
  </div>
}
