import useBEM from "hooks/useBEM";
import {useCharacterTabs} from "contexts/CharacterTabs";
import {useCharacterParams} from "contexts/CharacterParams";
import {useMemo} from "react";
import BottleEmpty from "icons/bottle/BottleEmpty";

const PowersBottle = () => {
  const classes = useBEM('powers-bottle');
  const {activeTab} = useCharacterTabs();
  const {parameters} = useCharacterParams();
  const values = useMemo(() => parameters?.powers || {}, [parameters.powers]);
  const used = useMemo(() => {
    return Object.values(values).reduce((partialSum, value) => partialSum + value, 0);
  }, [values]);

  return activeTab === 'powers' && <div className={classes({
    full: used === 6,
    empty: !used,
    bottle: used,
  })}>
    <BottleEmpty/>
  </div>
}

export default PowersBottle;
