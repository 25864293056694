import "styles/components/_app-error.scss";
import useBEM from "hooks/useBEM";
import GeneralGrim from "images/grim.png";
import GeneralGrim2x from "images/grim-2x.png";

export default function AppError({title = 'Sorry, an error occurred', children}) {
  const classes = useBEM('app-error');
  return <div className={classes()}>
    <picture>
      <source srcSet={GeneralGrim + ', ' + GeneralGrim2x + ' 2x'}/>
      <img width={275} height={212} alt={'General Grim'} src={GeneralGrim}/>
    </picture>
    <div className={classes('body')}>
      <h3 className={classes('title')}>
        {title}
      </h3>
      {children}
    </div>
  </div>
}
