export const appResources = [
  "assets/hdri/environment.hdr",
  "assets/models/base.glb",
  "assets/models/characters/altrini-girl.glb",
  "assets/models/characters/captain-altrini.glb",
  "assets/models/characters/kid-altrini.glb",
  "assets/models/characters/miss-altrini.glb",
  "assets/models/hair/hair01.glb",
  "assets/models/hair/hair02.glb",
  "assets/models/hair/hair03.glb",
  "assets/models/hair/hair04.glb",
  "assets/models/hair/hair05.glb",
  "assets/models/hair/hair06.glb",
  "assets/models/hair/hair07.glb",
  "assets/models/hair/hair08.glb",
  "assets/models/masks/mask-eyes-01.glb",
  "assets/models/masks/mask-eyes-02.glb",
  "assets/models/masks/mask-full-face.glb",
  "assets/models/masks/mask-full-head.glb",
  "assets/models/masks/mask-star.glb",
  "assets/models/accessories/cape.glb",
  "assets/models/accessories/earrings.glb",
  "assets/models/accessories/headphones.glb",
  "assets/background/light.png",
  "assets/background/medium.png",
  "assets/background/dark.png",
  "assets/background/blue.png",
  "assets/background/green.png",
  "assets/background/yellow.png",
];
