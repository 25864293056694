import {PUBLIC_URL} from "helpers/app";
import {appResources} from "helpers/resources";
import BottleFull from "images/character-app/bottle-full.png";
import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {useGLTF} from "@react-three/drei";

const client = axios.create();
const loadingState = {
  isLoading: undefined,
  loaded: {},
  total: {},
  progress: 0,
};
const LoadingStateContext = createContext({
  isLoading: true,
  progress: 0,
});

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export const LoadingStateProvider = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const total = Object.values(loadingState.total);
      const loaded = Object.values(loadingState.loaded);
      const totalSum = total.length > 0 ? total.reduce((a, b) => a + b) : 1;
      const loadedSum = loaded.length > 0 ? loaded.reduce((a, b) => a + b) : 0;
      loadingState.progress = loadedSum / totalSum;

      if (loadingState.progress >= 1) {
        loadingState.isLoading = false;
      }

      setLoading(loadingState.isLoading);
      setProgress(loadingState.progress);
    }

    if (loadingState.isLoading === undefined) {
      loadingState.isLoading = true;
      preloadImage(BottleFull).then(() => {
        appResources.forEach((url) => {
          const loadUrl = PUBLIC_URL + '/' + url;
          loadingState.total[url] = 1;
          loadingState.loaded[url] = 0;

          client.get(loadUrl, {
            onDownloadProgress: (progressEvent) => {
              if (progressEvent.total) {
                loadingState.total[url] = progressEvent.total;
              }
              else if (progressEvent?.event?.srcElement) {
                const contentLength = progressEvent?.event?.srcElement.getResponseHeader('content-length');
                if (contentLength) {
                  loadingState.total[url] = parseInt(contentLength);
                }
              }

              if (loadingState.total[url] > 1) {
                loadingState.loaded[url] = progressEvent.loaded;
              }
              updateProgress();
            },
          }).then(() => {
            loadingState.loaded[url] = loadingState.total[url];
            updateProgress();

            if (new RegExp('.glb$').test(loadUrl)) {
              useGLTF.preload(loadUrl);
            }
          }).catch(() => {
            toast.error('There was an error when loading the game. Please try reloading.');
          });
        });
      });
    }

    updateProgress();
  }, []);

  return <LoadingStateContext.Provider value={{
    isLoading,
    progress,
  }}>
    {props.children}
  </LoadingStateContext.Provider>
}

export default function useAppLoading() {
  return useContext(LoadingStateContext);
}
