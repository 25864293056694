import Button from "components/interface/Button";
import AddBoxIcon from "icons/AddBoxIcon";
import {useCallback, useEffect, useMemo, useState} from "react";
import {heroService} from "helpers/heroService";
import {getCharacterDefaults, useCharacterParams} from "contexts/CharacterParams";
import {useCharacterExport} from "contexts/CharacterExport";
import {encryptData, exportHero, renderHero} from "helpers/app";
import {toast} from "react-toastify";
import apiService from "helpers/apiService";

const WallButton = () => {
  const {parameters, colors} = useCharacterParams();
  const {scene} = useCharacterExport();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const characterDefaults = getCharacterDefaults(parameters?.character);
  const canSubmit = useMemo(() => {
    if (!parameters.powers || !colors) {
      return false;
    }

    return true;
  }, [parameters, colors]);

  const handleWallSave = useCallback(async () => {
    setLoading(true);
    try {
      setStatusMessage('Exporting files...');
      const token = await apiService.getToken();
      const heroImage = await encryptData(await renderHero(scene, false));
      const gltf = await encryptData(await exportHero(scene, 'gltf'));
      const usdz = await encryptData(await exportHero(scene, 'usdz'));

      heroService.addHero({
        name: [parameters.name || characterDefaults.name],
        tagline: [parameters.tagline || characterDefaults.tagline],
        config: [{
          value: {
            ...parameters,
            colors,
          },
        }],
        image: [{
          data: heroImage.data,
          filemime: heroImage.filemime,
          extension: 'png',
        }],
        gltf: [{
          data: gltf.data,
          filemime: gltf.filemime,
          extension: 'gltf',
        }],
        usdz: [{
          data: usdz.data,
          filemime: usdz.filemime,
          extension: 'usdz',
        }],
      }, {
        headers: {
          'Authorization': 'Bearer ' + token.value,
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength) {
            setStatusMessage('Uploading: ' + Math.round(progressEvent.loaded / totalLength * 100) + '%');
          }
        },
      }).then(() => {
        setLoading(false);
        setSuccess(true);
        toast('Your hero will soon appear on the Wall of Fame! Please be patient, it may take some time.');
      }).catch((e) => {
        setLoading(false);
        console.error(e);
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [scene, parameters, colors, characterDefaults.name, characterDefaults.tagline]);

  useEffect(() => {
    setSuccess(false);
  }, [parameters, colors]);

  return canSubmit && <Button disabled={isLoading || isSuccess} onClick={handleWallSave} variant={'square'} fullWidth
                              leftSection={<AddBoxIcon/>}>
    {isLoading ? statusMessage : (isSuccess ? 'Your hero was added' : 'Add to hero wall')}
  </Button>
}

export default WallButton;
