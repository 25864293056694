import "styles/components/_app-error.scss";
import AppError from "components/AppError";
import {PUBLIC_URL} from "helpers/app";

export default function NotFoundError() {
  return <AppError title={'Sorry, no results could be found'}>
    <p>
      Try going back to <a href={PUBLIC_URL + '/'}>homepage</a> or checking for typos.
    </p>
  </AppError>
}
