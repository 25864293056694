import useBEM from "hooks/useBEM";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";
import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import {useCallback, useMemo} from "react";
import LightIcon from "images/character-app/background/light.png";
import MediumIcon from "images/character-app/background/medium.png";
import DarkIcon from "images/character-app/background/dark.png";
import BlueIcon from "images/character-app/background/blue.png";
import GreenIcon from "images/character-app/background/green.png";
import YellowIcon from "images/character-app/background/yellow.png";
import Button from "components/interface/Button";
import {useCharacterExport} from "contexts/CharacterExport";
import {renderHero} from "helpers/app";
import {useCharacterParams} from "contexts/CharacterParams";

export default function RenderTab() {
  const classes = useBEM('character-tabs');
  const {scene} = useCharacterExport();
  const { parameters } = useCharacterParams();
  const { background } = parameters || {};

  const options = useMemo(() => {
    return [
      {
        value: 'light',
        title: 'Light',
        icon: LightIcon,
      },
      {
        value: 'medium',
        title: 'Medium',
        icon: MediumIcon,
      },
      {
        value: 'dark',
        title: 'Dark',
        icon: DarkIcon,
      },
      {
        value: 'blue',
        title: 'Blue',
        icon: BlueIcon,
      },
      {
        value: 'green',
        title: 'Green',
        icon: GreenIcon,
      },
      {
        value: 'yellow',
        title: 'Yellow',
        icon: YellowIcon,
      },
    ];
  }, []);

  const handleSave = useCallback(() => {
    renderHero(scene, background).then(blob => {
      if (blob) {
        const a = document.createElement('a');
        a.setAttribute('download', 'altrini-hero.png');
        a.setAttribute('href', window.URL.createObjectURL(blob));
        a.click();
        a.remove();
      }
    });
  }, [scene, background]);

  const handleShare = useCallback(async () => {
    const shareData = {};

    try {
      const blob = await renderHero(scene, background);
      const file = new File([blob], 'image.png', {type: blob.type});
      shareData.files = [file];

      if (navigator.canShare(shareData)) {
        await navigator.share(shareData);
      }
    } catch (e) {
      console.error(e);
    }

    handleSave();
  }, [scene, background, handleSave]);

  return <CharacterTabsPanel value={'save__render'}>
    <div className={classes('panel-inner')}>
      <h4 className={classes('panel-title')}>
        Choose your background
      </h4>
    </div>
    <CharacterOptions name={'background'} options={options}/>
    <div className={classes('panel-inner')}>
      <Button variant={'secondary'} onClick={handleShare}>Save image</Button>
    </div>
  </CharacterTabsPanel>
}
