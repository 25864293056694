import "styles/components/_install-prompt.scss";
import {useCallback, useEffect} from "react";
import {toast} from "react-toastify";
import useBEM from "hooks/useBEM";
import Button from "components/interface/Button";
import {isStandalone} from "helpers/app";

let deferredPrompt;

function InstallPromptChrome() {
  const classes = useBEM('install-prompt');
  const handleInstall = useCallback(async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      deferredPrompt = null;
      if (outcome === 'accepted') {
        console.log('😀 User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        console.log('😟 User dismissed the install prompt');
      }
      toast.dismiss('installPrompt');
    }
  }, []);

  return <div className={classes({
    chrome: true,
  })}>
    <div className={classes('body')}>
      Install our game to build characters offline.
    </div>
    <Button className={classes('button')} onClick={handleInstall}>Install game</Button>
  </div>
}

function InstallPromptNonChrome() {
  const classes = useBEM('install-prompt');

  return <div className={classes({
    chrome: true,
  })}>
    <div className={classes('body')}>
      Install our game to build characters offline. Press the "Share" button and select "Add to Home Screen" from the popup. Then tap "Add" in the top right corner to finish.
    </div>
  </div>
}

export default function InstallPrompt() {
  const handleInstallPrompt = useCallback(() => {
    toast(<InstallPromptChrome/>, {
      toastId: 'installPrompt',
    });
  }, []);

  useEffect(() => {
    if (!window.hasOwnProperty('BeforeInstallPromptEvent') && !isStandalone) {
      toast(<InstallPromptNonChrome/>, {
        toastId: 'installPrompt',
      });
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;
      handleInstallPrompt();
    });
  }, [handleInstallPrompt]);
}