/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 public/assets/models/masks/mask-star.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function MaskStar(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/masks/mask-star.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    <group {...props} dispose={null}>
      <group position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922}>
        <mesh geometry={nodes.MaskStar_1.geometry} material={characterMaterials.MaskPrimary} />
        <mesh geometry={nodes.MaskStar_2.geometry} material={characterMaterials.SuitBlack} />
        <mesh geometry={nodes.MaskStar_3.geometry} material={characterMaterials.SuitBadge} />
      </group>
    </group>
  )
}

//useGLTF.preload(PUBLIC_URL + '/assets/models/masks/mask-star.glb')
