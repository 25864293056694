/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/base.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import {PUBLIC_URL} from "helpers/app";

export function Base(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/base.glb');
  materials.Base.transparent = true;

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Base.geometry} material={materials.Base} position={[0, -0.01, 0]} />
    </group>
  )
}

//useGLTF.preload(PUBLIC_URL + '/assets/models/base.glb')
