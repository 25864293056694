import "styles/components/character-app/_back-button.scss";
import useBEM from "hooks/useBEM";
import Button from "components/interface/Button";
import BackIcon from "icons/BackIcon";
import {useCallback} from "react";

export default function BackButton() {
  const classes = useBEM('back-button');
  const webUrl = process.env.REACT_APP_WEB_URL;

  const handleClick = useCallback(() => {
    window.location = webUrl;
  }, [webUrl]);

  return webUrl && <Button className={classes()} variant={'primary'} leftSection={<BackIcon/>} onClick={handleClick}>Back to site</Button>
}
