/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/masks/mask-full-face.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function MaskFullFace(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/masks/mask-full-face.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);
  
  return (
    <group {...props} dispose={null}>
      <group position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922}>
        <mesh name="MaskFullFace" geometry={nodes.MaskFullFace.geometry} material={characterMaterials.MaskPrimary} morphTargetDictionary={nodes.MaskFullFace.morphTargetDictionary} morphTargetInfluences={nodes.MaskFullFace.morphTargetInfluences} />
        <mesh name="MaskFullFace_1" geometry={nodes.MaskFullFace_1.geometry} material={characterMaterials.SuitSecondary} morphTargetDictionary={nodes.MaskFullFace_1.morphTargetDictionary} morphTargetInfluences={nodes.MaskFullFace_1.morphTargetInfluences} />
        <mesh name="MaskFullFace_2" geometry={nodes.MaskFullFace_2.geometry} material={characterMaterials.SuitBadge} morphTargetDictionary={nodes.MaskFullFace_2.morphTargetDictionary} morphTargetInfluences={nodes.MaskFullFace_2.morphTargetInfluences} />
      </group>
    </group>
  )
}

//useGLTF.preload(PUBLIC_URL + '/assets/models/masks/mask-full-face.glb')
