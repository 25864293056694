/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/accessories/earrings.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import {PUBLIC_URL} from "helpers/app";
import {useCharacterParams} from "contexts/CharacterParams";
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";

export function Earrings({leftOnly, rightOnly, ...props}) {
  const { showEars } = useCharacterParams();
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/accessories/earrings.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    showEars && <group {...props} dispose={null}>
      {!rightOnly && <group position={[0, 1.065, -0.127]}>
        <mesh name="EarringLeft_1" geometry={nodes.EarringLeft_1.geometry} material={characterMaterials.SuitPrimary} morphTargetDictionary={nodes.EarringLeft_1.morphTargetDictionary} morphTargetInfluences={nodes.EarringLeft_1.morphTargetInfluences} />
        <mesh name="EarringLeft_2" geometry={nodes.EarringLeft_2.geometry} material={characterMaterials.Accessory} morphTargetDictionary={nodes.EarringLeft_2.morphTargetDictionary} morphTargetInfluences={nodes.EarringLeft_2.morphTargetInfluences} />
      </group>}
      {!leftOnly && <group position={[0, 1.065, -0.127]}>
        <mesh name="EarringRight_1" geometry={nodes.EarringRight_1.geometry} material={characterMaterials.SuitPrimary} morphTargetDictionary={nodes.EarringRight_1.morphTargetDictionary} morphTargetInfluences={nodes.EarringRight_1.morphTargetInfluences} />
        <mesh name="EarringRight_2" geometry={nodes.EarringRight_2.geometry} material={characterMaterials.Accessory} morphTargetDictionary={nodes.EarringRight_2.morphTargetDictionary} morphTargetInfluences={nodes.EarringRight_2.morphTargetInfluences} />
      </group>}
    </group>
  )
}

//useGLTF.preload(PUBLIC_URL + '/assets/models/accessories/earrings.glb')
