import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";
import {useCharacterParams} from "contexts/CharacterParams";
import * as THREE from "three";
import {Canvas} from "@react-three/fiber";
import {Environment, useTexture} from "@react-three/drei";
import Character from "components/CharacterApp/characters/Character";
import {useState} from "react";
import {PUBLIC_URL} from "helpers/app";

const Background = () => {
  const {parameters} = useCharacterParams();
  const {background} = parameters;
  const [texture, setTexture] = useState(null);

  useTexture(PUBLIC_URL + '/assets/background/' + background + '.png', (texture) => {
    if (texture && typeof texture.then !== 'function') {
      texture.colorSpace = 'srgb';
      setTexture(texture);
    }
  });

  return texture && <primitive attach="background" object={texture} />
}

function Preview() {
  return <div className={'render-preview'}>
    <Canvas shadows
            frameloop={'demand'}
            camera={{position: [0, 0.45, 2], rotation: [Math.PI * 0.05, 0, 0], up: [0, 1, 0], fov: 55}}
            gl={{
              antialias: true,
              alpha: true,
              pixelRatio: 2,
              toneMapping: THREE.NoToneMapping,
            }}
            style={{
              aspectRatio: 1,
            }}
    >
      <Environment files={PUBLIC_URL + '/assets/hdri/environment.hdr'} environmentIntensity={0.5}
                   environmentRotation={[0, -Math.PI / 4, 0]}/>
      <Character/>
      <directionalLight
        position={[2, 3, 3]}
        intensity={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-radius={6}
        shadow-blurSamples={25}
      />
      <Background/>
    </Canvas>
  </div>
}

export default function PreviewTab() {
  return <CharacterTabsPanel value={'save__render'} className={'character-tabs__panel--name-save__preview'}>
    <Preview/>
  </CharacterTabsPanel>
}