import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {KidAltrini} from "components/CharacterApp/characters/KidAltrini";
import {CaptainAltrini} from "components/CharacterApp/characters/CaptainAltrini";
import {AltriniGirl} from "components/CharacterApp/characters/AltriniGirl";
import {MissAltrini} from "components/CharacterApp/characters/MissAltrini";

import {Hair01} from "components/CharacterApp/hair/Hair01";
import {Hair02} from "components/CharacterApp/hair/Hair02";
import {Hair03} from "components/CharacterApp/hair/Hair03";
import {Hair04} from "components/CharacterApp/hair/Hair04";
import {Hair05} from "components/CharacterApp/hair/Hair05";
import {Hair06} from "components/CharacterApp/hair/Hair06";
import {Hair07} from "components/CharacterApp/hair/Hair07";
import {Hair08} from "components/CharacterApp/hair/Hair08";
import {MaskEyes01} from "components/CharacterApp/masks/MaskEyes01";
import {MaskEyes02} from "components/CharacterApp/masks/MaskEyes02";
import {MaskFullHead} from "components/CharacterApp/masks/MaskFullHead";
import {MaskFullFace} from "components/CharacterApp/masks/MaskFullFace";
import {MaskStar} from "components/CharacterApp/masks/MaskStar";
import {Cape} from "components/CharacterApp/accesories/Cape";
import {Earrings} from "components/CharacterApp/accesories/Earrings";
import {Headphones} from "components/CharacterApp/accesories/Headphones";
import {hexToHsb, hsbToHex} from "helpers/colorConverters";

const characterDefaults = {
  'KidAltrini': {
    name: 'Kid Altrini',
    tagline: 'Fuelling futures, one bottle at a time!',
  },
  'AltriniGirl': {
    name: 'Altrini Girl',
    tagline: 'Fuelling futures, one bottle at a time!',
  },
  'CaptainAltrini': {
    name: 'Captain Altrini',
    tagline: 'Fuelling futures, one bottle at a time!',
  },
  'MissAltrini': {
    name: 'Miss Altrini',
    tagline: 'Fuelling futures, one bottle at a time!',
  },
};
const CharacterParamsContext = createContext({});

export const getCharacterDefaults = (character) => {
  return character && characterDefaults[character] ? characterDefaults[character] : {
    name: 'Kid Altrini',
    tagline: 'Fuelling futures, one bottle at a time!',
  }
}

export const CharacterParamsProvider = (props) => {
  const [parameters, setParameters] = useState(JSON.parse(localStorage.getItem('characterParameters')) || {
    mask: false,
    cape: false,
    accessory: false,
    name: '',
    tagline: '',
    flavour: 'strawberry',
    background: 'light',
  });
  const [colors, setColors] = useState(JSON.parse(localStorage.getItem('characterColors')) || {
    'skin': {color: '#808080'},
    'hair': {color: '#808080'},
    'eye': {color: '#808080'},
    'mask': {color: '#808080'},
    'cape': {color: '#808080'},
    'accessory': {color: '#808080'},
    'primary': {},
    'primary_dark': {},
    'secondary': {},
    'secondary_dark': {},
    'badge': {},
  });
  const [character, setCharacter] = useState(null);
  const [hair, setHair] = useState(null);
  const [mask, setMask] = useState(null);
  const [cape, setCape] = useState(null);
  const [accessory, setAccessory] = useState(null);

  const showHair = useMemo(() => {
    return parameters.mask !== 'fullhead';
  }, [parameters.mask]);

  const showEars = useMemo(() => {
    return parameters.mask !== 'fullhead' && parameters.accessory !== 'headphones';
  }, [parameters.mask, parameters.accessory]);

  const setColor = useCallback((key, value = null) => {
    const newColors = {...colors};
    const items = typeof key === 'object' ? key : {
      [key]: value,
    };

    for (const [key, value] of Object.entries(items)) {
      newColors[key] = value;

      switch (key) {
        default: break;
        case 'primary':
        case 'secondary':
          if (value) {
            const hsb = hexToHsb(value.color);
            hsb.b = 25;
            newColors[key + '_dark'] = {
              color: hsbToHex(hsb),
            };
          }
          break;
      }
    }

    setColors(newColors);
    // @todo persistent store state?
    localStorage.setItem('characterColors', JSON.stringify(newColors));
  }, [colors, setColors]);

  const setParameter = useCallback((key, value, updateParameters = null) => {
    const newParameters = updateParameters ? updateParameters : parameters;
    newParameters[key] = value;

    if (key === 'character') {
      switch (value) {
        default:
          console.error(`Unable to select ${value} character.`);
          break;
        case 'KidAltrini':
          setParameter('hair', '01', newParameters);
          setColor({
            'primary': {
              color: '#E70005',
            },
            'secondary': {
              color: '#2FB1E7',
              metallic: 1,
            },
            'badge': {
              color: '#E7A900',
            },
          });
          break;
        case 'CaptainAltrini':
          setParameter('hair', '02', newParameters);
          setColor({
            'primary': {
              color: '#007A45',
            },
            'secondary': {
              color: '#E7A900',
            },
            'badge': {
              color: '#003A29',
            },
          });
          break;
        case 'AltriniGirl':
          setParameter('hair', '03', newParameters);
          setColor({
            'primary': {
              color: '#2FB1E7',
              metallic: 1,
            },
            'secondary': {
              color: '#E70005',
            },
            'badge': {
              color: '#E7A900',
            },
          });
          break;
        case 'MissAltrini':
          setParameter('hair', '04', newParameters);
          setColor({
            'primary': {
              color: '#E7A900',
            },
            'secondary': {
              color: '#007A45',
            },
            'badge': {
              color: '#E7A900',
            },
          });
          break;
      }
    }

    setParameters({...newParameters});
    // @todo persistent store state?
    localStorage.setItem('characterParameters', JSON.stringify(newParameters));
  }, [parameters, setColor]);

  useEffect(() => {
    if (typeof parameters === 'object') {
      for(const [key, value] of Object.entries(parameters)) {
        switch (key) {
          default:
            break;
          case 'character':
            let newCharacter = null;
            switch (value) {
              default:
                console.error(`Unable to select ${value} character.`);
                break;
              case 'KidAltrini':
                newCharacter = <KidAltrini/>;
                break;
              case 'CaptainAltrini':
                newCharacter = <CaptainAltrini/>;
                break;
              case 'AltriniGirl':
                newCharacter = <AltriniGirl/>;
                break;
              case 'MissAltrini':
                newCharacter = <MissAltrini/>;
                break;
            }
            setCharacter(newCharacter);
            break;
          case 'hair':
            let newHair;
            switch (value) {
              default:
                console.error(`Unable to select ${value} hair.`);
                break;
              case false:
                newHair = null;
                break;
              case '01':
                newHair = <Hair01/>
                break;
              case '02':
                newHair = <Hair02/>
                break;
              case '03':
                newHair = <Hair03/>
                break;
              case '04':
                newHair = <Hair04/>
                break;
              case '05':
                newHair = <Hair05/>
                break;
              case '06':
                newHair = <Hair06/>
                break;
              case '07':
                newHair = <Hair07/>
                break;
              case '08':
                newHair = <Hair08/>
                break;
            }
            setHair(newHair);
            break;
          case 'mask':
            let newMask;
            switch (value) {
              default:
                console.error(`Unable to select ${value} mask.`);
                break;
              case false:
                newMask = null;
                break;
              case 'eyes01':
                newMask = <MaskEyes01/>
                break;
              case 'eyes02':
                newMask = <MaskEyes02/>
                break;
              case 'fullface':
                newMask = <MaskFullFace/>
                break;
              case 'fullhead':
                newMask = <MaskFullHead/>
                break;
              case 'star':
                newMask = <MaskStar/>
                break;
            }
            setMask(newMask);
            break;
          case 'cape':
            setCape(value ? <Cape/> : null);
            break;
          case 'accessory':
            let newAccessory;
            switch (value) {
              default:
                console.error(`Unable to select ${value} accessory.`);
                break;
              case false:
                newAccessory = null;
                break;
              case 'headphones':
                newAccessory = <Headphones/>
                break;
              case 'earrings':
                newAccessory = <Earrings/>
                break;
              case 'earring_left':
                newAccessory = <Earrings leftOnly={true}/>
                break;
              case 'earring_right':
                newAccessory = <Earrings rightOnly={true}/>
                break;
            }
            setAccessory(newAccessory);
            break;
        }
      }
    }
  }, [parameters]);

  useEffect(() => {
    if (!parameters.character) {
      setParameter('character', 'KidAltrini');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CharacterParamsContext.Provider
      value={{
        character,
        showEars,
        showHair,
        hair,
        mask,
        cape,
        accessory,
        colors,
        setColor,
        parameters,
        setParameter,
      }}
    >
      {props.children}
    </CharacterParamsContext.Provider>
  );
};

export const useCharacterParams = () => {
  return useContext(CharacterParamsContext);
};
