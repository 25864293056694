import "styles/components/_app-error.scss";
import AppError from "components/AppError";

export default function RuntimeError() {
  return <AppError>
    <p>
      Try reloading the game or contact us if the error persists.<br/>
      Hopefully General Grim hasn’t broken our servers.
    </p>
  </AppError>
}
